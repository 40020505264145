import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {

    constructor() { }

    intializePrepareDetails: any = {
        'PreparePageUrl': '',
        'SourceUrlType': '',
        'PrepareType': '',
        'EnvelopeID': '',
        'EmailId': '',
        'PageName': '',
        'Token':'',
        'EnvelopeOrTemplateId':'',
        'SourceURL':''
    };
    disableTabOptions: any;
    allImagesProcessed: any;
    allImagesArray: any;
    isEnvelopeEditable:any;
    enableDependenciesFeature:any;
    displayMessage:any;
    currentClickedDroppedControl:any;
    arrCopyPasteCtrl:any;
    arrUndoRedoCtrl:any;
    arrCopyPasteCtrlsTemp:any;
    isCopiedSelecteditems:any;
    allImageControls:any;
    controlDependencyFieldsArray:any;
    controlPageGuidNumArray:any;
    isSendEnvelope:any;
    userPlanDetails:any = {
        'UserPlanType': '',
        'UserPlanRange': '',
        'UpgradeLink': '',
        'UserPlanName': '',
        'UserUnits': ''
    };
    selecteditemsLength:any;
    arrUndoCtrlsCount:any;
    currentPagination:any;
    clickedSignMeOut:any;
    userDetails:any;
    dependCtrlMoved:any;
    recipientDetails:any;
    recipientColorDetails:any;
    defaultControlStyles:any;
    ValidationsDateOptions:any;
    Dateformat:any;
    DateformatValue:any;
    Culture:any;

    setCulture( value: any) {
        this.Culture = value;
    }

    getCulture() {
        return this.Culture;
    }

    setValidationsDateOptions( value: any) {
        this.ValidationsDateOptions = value;
    }

    getValidationsDateOptions() {
        return this.ValidationsDateOptions;
    }

    setDateformat( value: any) {
        this.Dateformat = value;
    }

    getDateformat() {
        return this.Dateformat;
    }

    setDateformatValue( value: any) {
        this.DateformatValue = value;
    }

    getDateformatValue() {
        return this.DateformatValue;
    }

    setDefaultControlStyles( value: any) {
        this.defaultControlStyles = value;
    }

    getDefaultControlStyles() {
        return this.defaultControlStyles;
    }

    setIntializePrepareDetails(key: any, value: any) {
        this.intializePrepareDetails[key] = value;
    }

    getIntializePrepareDetails() {
        return this.intializePrepareDetails;
    }

    setDisableTabOptions(value: any) {
        this.disableTabOptions = value;
    }

    getDisableTabOptions() {
        return this.disableTabOptions;
    }

    setAllImagesProcessed(value: any) {
        this.allImagesProcessed = value;
    }

    getAllImagesProcessed() {
        return this.allImagesProcessed;
    }

    setAllImagesArray(value: any) {
        this.allImagesArray = value;
    }

    getAllImagesArray() {
        return this.allImagesArray;
    }

    setIsEnvelopeEditable(value: any) {
        this.isEnvelopeEditable = value;
    }

    getIsEnvelopeEditable() {
        return this.isEnvelopeEditable;
    }

    setEnableDependenciesFeature(value: any) {
        this.enableDependenciesFeature = value;
    }

    getEnableDependenciesFeature() {
        return this.enableDependenciesFeature;
    }

    setDisplayMessage(value: any) {
        this.displayMessage = value;
    }

    getDisplayMessage() {
        return this.displayMessage;
    }

    setCurrentClickedDroppedControl(value: any) {
        this.currentClickedDroppedControl = value;
    }

    getCurrentClickedDroppedControl() {
        return this.currentClickedDroppedControl;
    }

    setArrCopyPasteCtrl(value: any) {
        this.arrCopyPasteCtrl = value;
    }

    getArrCopyPasteCtrl() {
        return this.arrCopyPasteCtrl;
    }

    setArrUndoRedoCtrl(value: any) {
        this.arrUndoRedoCtrl = value;
    }

    getArrUndoRedoCtrl() {
        return this.arrUndoRedoCtrl;
    }

    setArrCopyPasteCtrlsTemp(value: any) {
        this.arrCopyPasteCtrlsTemp = value;
    }

    getArrCopyPasteCtrlsTemp() {
        return this.arrCopyPasteCtrlsTemp;
    }

    setIsCopiedSelecteditems(value: any) {
        this.isCopiedSelecteditems = value;
    }

    getIsCopiedSelecteditems() {
        return this.isCopiedSelecteditems;
    }

    setAllImageControls(value: any) {
        this.allImageControls = value;
    }

    getAllImageControls() {
        return this.allImageControls;
    }

    setControlDependencyFieldsArray(value: any) {
        this.controlDependencyFieldsArray = value;
    }

    getControlDependencyFieldsArray() {
        return this.controlDependencyFieldsArray;
    }

    setControlPageGuidNumArray(value: any) {
        this.controlPageGuidNumArray = value;
    }

    getControlPageGuidNumArray() {
        return this.controlPageGuidNumArray;
    }

    setIsSendEnvelope(value: any) {
        this.isSendEnvelope = value;
    }

    getIsSendEnvelope() {
        return this.isSendEnvelope;
    }

    setUserPlanDetails(key:any, value: any) {
        this.userPlanDetails[key] = value;
    }

    getUserPlanDetails() {
        return this.userPlanDetails;
    }

    setSelecteditemsLength(value: any) {
        this.selecteditemsLength = value;
    }

    getSelecteditemsLength() {
        return this.selecteditemsLength;
    }

    setArrUndoCtrlsCount(value: any) {
        this.arrUndoCtrlsCount = value;
    }

    getArrUndoCtrlsCount() {
        return this.arrUndoCtrlsCount;
    }

    setCurrentPagination(value: any) {
        this.currentPagination = value;
    }

    getCurrentPagination() {
        return this.currentPagination;
    }

    setClickedSignMeOut(value: any) {
        this.clickedSignMeOut = value;
    }

    getClickedSignMeOut() {
        return this.clickedSignMeOut;
    }

    setUserDetails(value: any) {
        this.userDetails = value;
    }

    getUserDetails() {
        return this.userDetails;
    }

    setDependCtrlMoved(value: any) {
        this.dependCtrlMoved = value;
    }

    getDependCtrlMoved() {
        return this.dependCtrlMoved;
    }

    setRecipientDetails(value: any) {
        this.recipientDetails = value;
    }

    getRecipientDetails() {
        return this.recipientDetails;
    }

    setRecipientColorDetails(value: any) {
        this.recipientColorDetails = value;
    }

    getRecipientColorDetails() {
        return this.recipientColorDetails;
    }

    setTokenDetails(value: any) {
        this.userDetails = value;
    }

}
